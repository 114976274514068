<template>
  <div class="trade-sts">
    <!-- 交易概览 -->
    <el-card class="margin-t20">
      <div
        slot="header"
        class="card-head"
      >
        <span style="font-size: 16px; font-weight: bold;">交易概况</span>
        <el-date-picker
          v-model="totalTime"
          type="daterange"
          placeholder="选择日期范围"
          @change="getStsInfo"
        />
      </div>
      <div class="wd-zoom total-content">
        <div class="total-info">
          <TradeTotal :data="totalData1" />
          <TradeTotal :data="totalData2" />
          <TradeTotal :data="totalData3" />
        </div>
        <div class="total-funnel">
          <p class="add-percent">
            转化率:{{ tradeFunnelData.visit_add_percent }}
          </p>
          <p class="pay-percent">
            转化率:{{ tradeFunnelData.add_pay_percent }}
          </p>
          <p class="visit-percent">
            转化率:{{ tradeFunnelData.visit_pay_percent }}
          </p>
        </div>
      </div>
      <TradeLine
        :order-pay-gmv-list="orderPayGmvList"
        :total-amount-list="totalAmountList"
        :order-pay-total-list="orderPayTotalList"
        :pay-user-num-list="payUserNumList"
        :order-pay-num-list="orderPayNumList"
        :date="date"
      />
    </el-card>
    <!-- <el-card class="margin-t20">
      <div
        slot="header"
        class="card-head"
      >
        <span>交易构成</span>
        <el-date-picker
          v-model="typeTime"
          type="daterange"
          placeholder="选择日期范围"
          @change="getTradeContent"
        />
      </div> -->
    <!-- 订单金额分布 -->
    <!-- <TypeChart :type-price-data="typePriceData" />
    </el-card> -->
    <!-- 地域分布 -->
    <!-- <el-card class="margin-t20">
      <div
        slot="header"
        class="card-head"
      >
        <span>地域分布</span>
        <el-date-picker
          v-model="areaTime"
          type="daterange"
          placeholder="选择日期范围"
          @change="getTradeArea"
        />
      </div>
      <div class="top-content">
        <ProvinceChart
          :max-amount="maxAmount"
          :province-map-data="provinceMapData"
        />
        <div class="top-table">
          <el-table
            v-loading="loading"
            :data="tableData"
            width="400"
            :header-cell-style="{background: '#F0F2F8'}"
            :row-key="getRowKeys"
            @expand="getCity"
          >
            <el-table-column
              label="Top省份"
              prop="goods_name"
              type="expand"
            >
              <template slot-scope="scope">
                <el-table
                  v-loading="loading"
                  :data="scope.row.data"
                  :header-cell-style="{background: '#F0F2F8'}"
                >
                  <el-table-column
                    label="城市"
                    prop="city"
                  />
                  <el-table-column
                    label="付款金额"
                    prop="order_amount"
                  />
                  <el-table-column
                    label="付款人数"
                    prop="pay_user_num"
                  />
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
              label="Top省份"
              prop="province"
            />
            <el-table-column
              label="付款金额"
              prop="order_amount"
            />
            <el-table-column
              label="付款人数"
              prop="pay_user_num"
            />
          </el-table>
        </div>
      </div>
    </el-card> -->
  </div>
</template>
<script>
// import ProvinceChart from '@/components/store/provinceChart'
// import TypeChart from '@/components/store/typeChart'
import TradeTotal from '@/components/store/tradeTotal'
import TradeLine from '@/components/store/tradeLine'

export default {
  name: 'TradeSts',
  components: {
    // ProvinceChart,
    // TypeChart,
    TradeTotal,
    TradeLine
  },
  data() {
    return {
      totalTime: [new Date(new Date() - 24 * 3600 * 1000), new Date(new Date() - 24 * 3600 * 1000)], // 交易概览--时间
      startTime: '',
      areaTime: [new Date(new Date() - 24 * 3600 * 1000), new Date(new Date() - 24 * 3600 * 1000)], // 地域分布--时间
      typeTime: [new Date(new Date() - 24 * 3600 * 1000), new Date(new Date() - 24 * 3600 * 1000)], // 交易构成--时间
      tableData: [], // top5数据
      loading: false,
      typePriceData: {
        index: [],
        num: []
      },
      getRowKeys(row) {
        return row.city
      },
      // 概览数据
      totalData1: [
        { label: '访客数', value: 0, router: '', content: '统计时间内APP+H5+小程序所有的访问用户数' }
      ],
      totalData2: [
        { label: '下单人数', value: 0, router: '', content: '统计时间内生成订单的用户数' },
        { label: '下单笔数', value: 0, router: '', content: '统计时间内生成的订单数' },
        { label: 'GMV', value: 0, router: '', content: '统计时间内生成订单的所有订单金额（实付+优惠金额），包含付款和未付款' }
      ],
      totalData3: [
        { label: '付款人数', value: 0, router: '', content: '统计时间内订单支付成功后的用户数' },
        { label: '付款订单数', value: 0, router: '', content: '统计时间内用户支付成功后的订单数' },
        { label: '总交易额', value: 0, router: '', content: '统计时间内支付成功的订单的所有订单金额（实付+优惠金额）' },
        { label: '优惠券总额', value: 0, router: '', content: '统计时间内支付成功的订单所使用的优惠券抵扣的金额' },
        { label: '实付金额', value: 0, router: '', content: '统计时间内支付成功的订单的所有实际付款金额' },
        { label: '客单价', value: 0, router: '', content: '统计时间内实付金额/付款订单数' }
      ],
      tradeFunnelData: {
        visitor_num: 0,
        order_user_num: 0,
        pay_user_num: 0,
        visit_add_percent: 0, // 访客-下单转化率
        add_pay_percent: 0, // 下单-付款转化率
        visit_pay_percent: 0 // 付款-付款转化率
      },
      provinceMapData: [], // 省市地图数据
      maxAmount: 0, // 最高付款金额
      // 折线图
      orderPayGmvList: [], // GMV-图
      totalAmountList: [], // 总交易额-图
      orderPayTotalList: [], // 付款总额-图
      payUserNumList: [], // 付款人数-图
      orderPayNumList: [], // 订单数-图
      date: [] // 横坐标日期

    }
  },
  mounted() {
    this.getTradeContent()
    this.getStsInfo()
    // this.getTradeArea()
  },
  methods: {
    // 交易概况
    getStsInfo() {
      this.$axios.post(this.$api.stat.trade_stat, {
        suppliers_id: Number(localStorage.getItem('admin_type_value')),
        begin_time: Date.parse(this.totalTime[0]) / 1000,
        end_time: Date.parse(this.totalTime[1]) / 1000
      }).then((res) => {
        const r = res
        if (r.error === 0) {
          // 访客
          this.totalData1[0].value = r.data.visitor_num
          // 下单
          this.totalData2[0].value = r.data.order_user_num
          this.totalData2[1].value = r.data.order_add_num
          this.totalData2[2].value = r.data.order_pay_gmv
          // 付款
          this.totalData3[0].value = r.data.pay_user_num
          this.totalData3[1].value = r.data.order_pay_num
          this.totalData3[2].value = r.data.order_add_total
          this.totalData3[3].value = r.data.order_pay_coupon
          this.totalData3[4].value = r.data.order_pay_total
          this.totalData3[5].value = r.data.average_order_amount
          // 转换率
          this.tradeFunnelData = {
            visitor_num: r.data.visitor_num,
            order_user_num: r.data.order_user_num,
            pay_user_num: r.data.pay_user_num,
            visit_add_percent: r.data.visit_add_percent,
            add_pay_percent: r.data.add_pay_percent,
            visit_pay_percent: r.data.visit_pay_percent
          }
          // 折线图
          this.orderPayGmvList = r.data.order_pay_gmv_list// GMV-图
          this.totalAmountList = r.data.total_amount_list// 总交易额-图
          this.orderPayTotalList = r.data.order_pay_total_list// 付款总额-图
          this.payUserNumList = r.data.pay_user_num_list// 付款人数-图
          this.orderPayNumList = r.data.order_pay_num_list// 订单数-图
          this.date = r.data.date// 横坐标日期
        } else {
          this.$message(r.error_msg)
        }
      })
    },
    // 交易构成
    getTradeContent() {
      this.$axios.post(this.$api.stat.trade_content, {
        begin_time: Date.parse(this.typeTime[0]) / 1000,
        end_time: Date.parse(this.typeTime[1]) / 1000
      }).then((res) => {
        const r = res
        if (r.error === 0) {
          this.typePriceData = {
            index: r.data.index,
            num: r.data.num
          }
        } else {
          this.$message(r.error_msg)
        }
      })
    },
    // 地域分布
    getTradeArea() {
      this.$axios.post(this.$api.stat.trade_area, {
        begin_time: Date.parse(this.areaTime[0]) / 1000,
        end_time: Date.parse(this.areaTime[1]) / 1000
      }).then((res) => {
        const r = res
        if (r.error === 0) {
          this.tableData = r.data.area_order_list
          this.provinceMapData = r.data.area_map
          this.maxAmount = r.data.max_amount

          this.tableData.forEach((item, index) => {
            this.$set(item, 'data', [])
          })
        } else {
          this.$message(r.error_msg)
        }
      })
    },
    getCity(row) {
      this.$axios.post(this.$api.stat.get_city_order, {
        begin_time: Date.parse(this.areaTime[0]) / 1000,
        end_time: Date.parse(this.areaTime[1]) / 1000,
        province_id: row.province_id
      }).then((res) => {
        const r = res

        if (r.error === 0) {
          this.tableData.forEach((item, index) => {
            if (item.province_id === row.province_id) {
              item.data = r.data
            }
          })
        } else {
          this.$message(r.error_msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.card-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top-content{
    display: flex;
    flex-wrap: wrap;
}
.top-table{
    width: 400px;
}
.total-content{
    height: 300px;
    display: flex;
}
.total-funnel{
    width: 350px;
    height: 270px;
    background-image: url('https://img.hzanchu.com/acimg/2cf7e71458e39f10f6f18a753bc4c677.png');
    background-size: 300px;
    background-repeat: no-repeat;
    position: relative;
    p{
        font-size: 12px;
        color: #3c3c3c;
        position: absolute;
    }
    .add-percent{
        left: 200px;
        top: 55px;
    }
    .pay-percent{
        left: 200px;
        top: 125px;
    }
    .visit-percent{
        left: 250px;
        top: 90px;
    }
}
</style>
