var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trade-total" },
    _vm._l(_vm.data, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "trade-item" },
        [
          _c(
            "h4",
            [
              _vm._v(" " + _vm._s(item.label) + " "),
              _c(
                "el-tooltip",
                { attrs: { effect: "light", content: item.content } },
                [_c("span", { staticClass: "icon" }, [_vm._v("?")])]
              ),
            ],
            1
          ),
          _c("router-link", { attrs: { tag: "h5", to: item.router } }, [
            _vm._v(" " + _vm._s(item.value) + " "),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }