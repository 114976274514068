<template>
  <div class="trade-total">
    <div
      v-for="(item,index) in data"
      :key="index"
      class="trade-item"
    >
      <h4>
        {{ item.label }}
        <el-tooltip
          effect="light"
          :content="item.content"
        >
          <span class="icon">?</span>
        </el-tooltip>
      </h4>
      <router-link
        tag="h5"
        :to="item.router"
      >
        {{ item.value }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TradeTotal',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.trade-total{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.trade-item{
  min-width: 100px;
  padding: 15px 5px;
  h4{
    font-size: 12px;
    font-weight: 400;
    span{
      color: #F7B500;
      font-size: 10px;
      margin-left: 3px;
      cursor: pointer;
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 12px;
      text-align: center;
      line-height: 12px;
      border: 1px solid #F7B500;
    }
  }
  h5{
    font-size: 18px;
    margin-top: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0;
  }
}
</style>
