var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trade-sts" },
    [
      _c(
        "el-card",
        { staticClass: "margin-t20" },
        [
          _c(
            "div",
            {
              staticClass: "card-head",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
                [_vm._v("交易概况")]
              ),
              _c("el-date-picker", {
                attrs: { type: "daterange", placeholder: "选择日期范围" },
                on: { change: _vm.getStsInfo },
                model: {
                  value: _vm.totalTime,
                  callback: function ($$v) {
                    _vm.totalTime = $$v
                  },
                  expression: "totalTime",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "wd-zoom total-content" }, [
            _c(
              "div",
              { staticClass: "total-info" },
              [
                _c("TradeTotal", { attrs: { data: _vm.totalData1 } }),
                _c("TradeTotal", { attrs: { data: _vm.totalData2 } }),
                _c("TradeTotal", { attrs: { data: _vm.totalData3 } }),
              ],
              1
            ),
            _c("div", { staticClass: "total-funnel" }, [
              _c("p", { staticClass: "add-percent" }, [
                _vm._v(
                  " 转化率:" +
                    _vm._s(_vm.tradeFunnelData.visit_add_percent) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "pay-percent" }, [
                _vm._v(
                  " 转化率:" + _vm._s(_vm.tradeFunnelData.add_pay_percent) + " "
                ),
              ]),
              _c("p", { staticClass: "visit-percent" }, [
                _vm._v(
                  " 转化率:" +
                    _vm._s(_vm.tradeFunnelData.visit_pay_percent) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("TradeLine", {
            attrs: {
              "order-pay-gmv-list": _vm.orderPayGmvList,
              "total-amount-list": _vm.totalAmountList,
              "order-pay-total-list": _vm.orderPayTotalList,
              "pay-user-num-list": _vm.payUserNumList,
              "order-pay-num-list": _vm.orderPayNumList,
              date: _vm.date,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }