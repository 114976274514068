<template>
  <div class="trade-total-chart wd-content">
    <div id="trade-line-echart" />
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'TradeLine',
  props: {
    orderPayGmvList: {
      type: Array,
      default: () => []
    }, // GMV-图
    totalAmountList: {
      type: Array,
      default: () => []
    }, // 总交易额-图
    orderPayTotalList: {
      type: Array,
      default: () => []
    }, // 付款总额-图
    payUserNumList: {
      type: Array,
      default: () => []
    }, // 付款人数-图
    orderPayNumList: {
      type: Array,
      default: () => []
    }, // 订单数-图
    date: {
      type: Array,
      default: () => []
    } // 横坐标日期
  },
  data() {
    return {
      tradeEchart: '',
      tradeData: {}
    }
  },
  watch: {
    date() {
      this.echartsInit()
    }

  },
  mounted() {
    this.tradeEchart = echarts.init(document.getElementById('trade-line-echart'))
    this.echartsInit()
  },
  methods: {
    echartsInit() {
      this.tradeData = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['GMV', '总交易额', '付款总额', '付款人数', '订单数'],
          selected: {
            '总交易额': false,
            '付款总额': false,
            '付款人数': false,
            '订单数': false
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            // saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'GMV',
            type: 'line',
            data: this.orderPayGmvList
          },
          {
            name: '总交易额',
            type: 'line',
            data: this.totalAmountList
          },
          {
            name: '付款总额',
            type: 'line',
            data: this.orderPayTotalList
          },
          {
            name: '付款人数',
            type: 'line',
            stack: '总量',
            data: this.payUserNumList
          },
          {
            name: '订单数',
            type: 'line',
            data: this.orderPayNumList
          }
        ]
      }
      if (this.tradeData && typeof this.tradeData === 'object') {
        this.tradeEchart.setOption(this.tradeData, true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#trade-line-echart{
  height:420px;
  overflow: hidden;
  margin: 0 auto;
}
</style>
